<template>
  <div class="vue-box">
    <div class="c-panel">
      <div class="c-title">对接参数</div>
      <el-descriptions :contentStyle="rowCenter" :column="1" border size="medium">
        <el-descriptions-item label="商户号">{{mchId}}</el-descriptions-item>
        <el-descriptions-item label="api密钥">{{secretKey}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mchId: null,
      role: null,
      secretKey: null,
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      if (this.sa_admin.role == 1) {
        this.sa.ajax(
          "/v1/merchant/getMerchantInfo",
          function (res) {
            this.mchId = res.data.mchId;
            this.secretKey = res.data.secretKey;
          }.bind(this),
          defaultCfg
        );

      }
    },
  },
  created: function () {
    this.f5();
  },
};
  </script>
  
<style scoped>
.el-row {
  margin-top: 30px;
  line-height: 25px;
}
</style>